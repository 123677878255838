<template>
  <div class="claim-device">
    <div class="row">
      <div class=" col">
        <label for="device_code" class="device-label">Device Code</label>
        <input type="text" v-model="deviceCode" class="w-100"
               maxlength="256" placeholder="Device Code" id="device_code">
      </div>

      <div class="col">
        <label for="device_name" class="device-label">Device Name</label>
        <input type="text" v-model="deviceName" class="w-100"
               maxlength="256" placeholder="Device name" id="device_name">
      </div>
    </div>
      <div class="footer mt-2">
        <button class="button" @click="$bvModal.hide(modal)">Close</button>
        <button class="button" @click="claimDevice()">Claim</button>
      </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'

export default {
  name: 'claim-device',
  props: {
    modal: String
  },
  data: function () {
    return {
      showButtons: true,
      displayDevice: null,
      deviceCode: '',
      deviceName: ''
    }
  },
  created () {
  },
  methods: {
    claimDevice: async function () {
      let response = await DataProvider.claimDevice(this.deviceCode, this.deviceName)
      if (!response.success) {
        ErrorHelper.displayDataErrorToast(response)
      } else {
        this.$bvToast.toast(`Success! ${this.deviceName} will be added to your device list.`,
          {
            title: 'You\'ve Claimed a new Device!',
            variant: 'success',
            toaster: 'b-toaster-top-center',
            noAutoHide: true
          })
        this.$emit('change', this.deviceCode)
        this.$bvModal.hide(this.modal)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .device-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

</style>
