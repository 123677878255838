var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fleet" },
    [
      _vm.showMenu
        ? _c(
            "div",
            {
              staticClass: "sidebar-toggle",
              on: { click: _vm.toggleShowMenu }
            },
            [_c("i", { staticClass: "fa fa-chevron-left" })]
          )
        : _vm._e(),
      !_vm.showMenu
        ? _c(
            "div",
            {
              staticClass: "sidebar-toggle",
              on: { click: _vm.toggleShowMenu }
            },
            [_c("i", { staticClass: "fa fa-chevron-down" })]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMenu,
              expression: "showMenu"
            }
          ],
          staticClass: "sidebar animate-in slide-left"
        },
        [
          _c("img", {
            staticClass: "sidebar-logo",
            attrs: { src: _vm.sidebarIcon }
          }),
          _c(
            "div",
            { staticClass: "filter-box" },
            [
              _c(
                "b-button",
                {
                  attrs: { pill: "", id: "add-device-button" },
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.show("claim-device-modal")
                    }
                  }
                },
                [_vm._v("Add Device")]
              ),
              _c(
                "b-tooltip",
                {
                  attrs: {
                    target: "add-device-button",
                    noninteractive: "",
                    variant: "success",
                    delay: {
                      show: _vm.$config.general.tooltipShowDelay,
                      hide: _vm.$config.general.tooltipHideDelay
                    }
                  }
                },
                [_vm._v(" Claim a new Device ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { pill: "", id: "share-device-button" },
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.show("share-device-modal")
                    }
                  }
                },
                [_vm._v("Share Device")]
              ),
              _c(
                "b-tooltip",
                {
                  attrs: {
                    target: "share-device-button",
                    noninteractive: "",
                    variant: "success",
                    delay: {
                      show: _vm.$config.general.tooltipShowDelay,
                      hide: _vm.$config.general.tooltipHideDelay
                    }
                  }
                },
                [_vm._v(" Share a Device with Others ")]
              )
            ],
            1
          ),
          _c("device-list", {
            ref: "deviceList",
            staticClass: "mt-3",
            on: {
              "markers-change": _vm.markersChange,
              "view-change": _vm.viewChange
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "map-container" },
        [
          _c("map-display", {
            attrs: { markers: _vm.markers, viewport: _vm.viewPort }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "claim-device-modal",
            centered: "",
            size: "m",
            "hide-footer": "",
            title: "Claim Device"
          }
        },
        [
          _c("claim-device", {
            attrs: { modal: "claim-device-modal" },
            on: { change: _vm.refreshDevices }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "share-device-modal",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Share Devices"
          }
        },
        [_c("share-device", { attrs: { modal: "share-device-modal" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }