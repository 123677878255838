var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "claim-device" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "label",
          { staticClass: "device-label", attrs: { for: "device_code" } },
          [_vm._v("Device Code")]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.deviceCode,
              expression: "deviceCode"
            }
          ],
          staticClass: "w-100",
          attrs: {
            type: "text",
            maxlength: "256",
            placeholder: "Device Code",
            id: "device_code"
          },
          domProps: { value: _vm.deviceCode },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.deviceCode = $event.target.value
            }
          }
        })
      ]),
      _c("div", { staticClass: "col" }, [
        _c(
          "label",
          { staticClass: "device-label", attrs: { for: "device_name" } },
          [_vm._v("Device Name")]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.deviceName,
              expression: "deviceName"
            }
          ],
          staticClass: "w-100",
          attrs: {
            type: "text",
            maxlength: "256",
            placeholder: "Device name",
            id: "device_name"
          },
          domProps: { value: _vm.deviceName },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.deviceName = $event.target.value
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "footer mt-2" }, [
      _c(
        "button",
        {
          staticClass: "button",
          on: {
            click: function($event) {
              return _vm.$bvModal.hide(_vm.modal)
            }
          }
        },
        [_vm._v("Close")]
      ),
      _c(
        "button",
        {
          staticClass: "button",
          on: {
            click: function($event) {
              return _vm.claimDevice()
            }
          }
        },
        [_vm._v("Claim")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }