<template>
  <div class="fleet">
    <div class="sidebar-toggle" @click="toggleShowMenu" v-if="showMenu">
      <i class="fa fa-chevron-left"></i>
    </div>
    <div class="sidebar-toggle" @click="toggleShowMenu" v-if="!showMenu">
      <i class="fa fa-chevron-down"></i>
    </div>
    <div class="sidebar animate-in slide-left" v-show="showMenu">
      <img class="sidebar-logo" :src="sidebarIcon">
      <div class="filter-box">
        <b-button pill @click="$bvModal.show('claim-device-modal')" id="add-device-button">Add Device</b-button>
        <b-tooltip target="add-device-button" noninteractive variant="success"
                   :delay="{ show: $config.general.tooltipShowDelay, hide: $config.general.tooltipHideDelay }">
          Claim a new Device
        </b-tooltip>
        <b-button pill @click="$bvModal.show('share-device-modal')" id="share-device-button">Share Device</b-button>
        <b-tooltip target="share-device-button" noninteractive variant="success"
                   :delay="{ show: $config.general.tooltipShowDelay, hide: $config.general.tooltipHideDelay }">
          Share a Device with Others
        </b-tooltip>
      </div>
    <device-list class="mt-3" v-on:markers-change="markersChange" v-on:view-change="viewChange" ref="deviceList"></device-list>
    </div>
    <div class="map-container">
      <map-display v-bind:markers="markers" v-bind:viewport="viewPort"></map-display>
    </div>

    <!--   Device Claim Modal Template-->
    <b-modal id="claim-device-modal" centered class="modal-content" size="m" hide-footer title="Claim Device">
      <claim-device @change="refreshDevices" modal="claim-device-modal"></claim-device>
    </b-modal>

    <!--   Device Share Modal Template-->
    <b-modal id="share-device-modal" centered class="modal-content" size="xl" hide-footer title="Share Devices">
      <share-device modal="share-device-modal"></share-device>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import MapDisplay from '../components/helpers/MapDisplay'
import DeviceList from '../components/device/DeviceList'
import ClaimDevice from '../components/device/ClaimDevice'
import ShareDevice from '../components/device/ShareDevice'

export default {
  name: 'fleet-view',
  components: {
    ClaimDevice,
    DeviceList,
    MapDisplay,
    ShareDevice
  },
  data: function () {
    return {
      markers: [],
      viewPort: {
        zoom: 3,
        lat: 0,
        lng: 0
      },
      showMenu: true
    }
  },
  mounted () {
    let options = {}
    if (window.innerWidth < 600) {
      options.fullscreenControl = false
    }
    this.$maps.showMapControls(options)
  },
  methods: {
    refreshDevices: function () {
      console.log('Got Refresh Call')
      this.$refs.deviceList.refreshList()
    },
    viewChange: function (newView) {
      console.log('ViewChange on Device View Component', newView)
      this.viewPort = newView
    },
    markersChange: function (newMarkers) {
      console.log('markersChange on Fleet View Component', newMarkers)
      this.markers = newMarkers
    },
    toggleShowMenu: function () {
      this.showMenu = !this.showMenu
    }
  },
  computed: {
    sidebarIcon: function () {
      if (process.env.VUE_APP_CONFIG_SHOW_BETA_ICON) {
        return require('../assets/Pro-tekt-logo-beta.png')
      } else {
        return require('../assets/Pro-tekt-logo.png')
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/variables';
  @import '@/animations';

  .filter-box {
    display: grid;
    margin: 10px;
    padding: 10px;
    grid-auto-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 8px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border-radius: 17px;
    background-color: $default-content-background;
  }

  .map-container {
    width: calc(100% - 310px);
    position: absolute;
    top: 0;
    right: 310px;
    height: 100%
  }

  .sidebar-toggle {
    position: absolute;
    display: none;
    color: white;
    font-size: 2em;
    top: 0;
    right: 50px;
    width: 60px;
    height: 60px;
    z-index: 15;
    background: $theme-color-background-4;
    border-radius: 10px;
  }

  #add-device-button {
    color: yellow;
    border-color: yellow;
  }

  @media screen and (max-width: 600px) {
    .map-container {
      width: calc(100% - 60px);
      right: 60px;
    }
    .sidebar-toggle {
      display: block;
    }
  }

</style>
