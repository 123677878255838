<template>
  <div class="device-list">
    <div>
      <div class="green-divider"></div>
      <b-form-radio-group buttons v-model="listMode" button-variant="success">
        <b-form-radio value="all">All</b-form-radio>
        <b-form-radio value="plant">By Plant</b-form-radio>
      </b-form-radio-group>

      <div class="unpaid-popup" v-if="hasInvalidSubscriptions">
        <b-alert variant="danger" show dismissible>
          <p>One or more of your devices currently has an expired subscription,
            which has temporarily paused tracking. To resume tracking, please update your subscription details using the
            <strong>Subscription</strong> tab of the <strong>Preferences page</strong> or by
            <router-link to="/subscribe">clicking here.</router-link></p>
          If you need any assistance, feel free to email our support team at support@protektgps.com or
          call 1300 95 25 30.</b-alert>
      </div>

      <loading-box v-bind:loading="loading" fontsize="2em"></loading-box>
        <div class="device-list" v-show="listMode==='all'">
          <h1 class="sidebar-heading mt-2">All Devices</h1>
          <div v-if="deviceList.length === 0" class="sub-heading">
            You don't have any devices yet. Click 'Add Device' to claim one.
          </div>

          <div v-for="device of deviceList" v-bind:key="device.device_id"
            :class="{ unpaid: !device.has_subscription }">
            <div class="position-relative mt-1">
              <div class="favorite_icon clickable" @click="toggleDeviceFavorite(device)"
                   :id="'fav-icon' + device.device_id"
                   v-b-tooltip title="Toggle Favourite"
              >
                <i class="fa clickable" v-bind:class="{
              'fa-star': device.favorite,
              'fa-star-o': !device.favorite,
            }"  ></i>
              </div>
              <DeviceAvatar :device="device" show-indicators enable_link />
            </div>
          </div>
        </div>
      <div class="device-list" v-show="listMode==='plant'">
        <h1 class="sidebar-heading mt-2">Devices by Plant</h1>
        <div v-if="deviceList.length === 0" class="sub-heading">
          You don't have any devices yet. Click 'Add Device' to claim one.
        </div>
        <div class="right_menu_item sub-heading" v-for="plant of plantList" v-bind:key="plant.plant">
          <div class="plant-heading">Plant: {{ plant.text }}</div>
          <div v-for="device of plant.devices" v-bind:key="device.device_id" class="mt-1">
            <DeviceAvatar :device="device" show-indicators enable_link />
          </div>
        </div>
      </div>

    </div>
    <b-modal id="modal-no-sub" centered class="modal-content" size="lg" hide-footer title="No Valid Subscription">
      <div>
        The owner of this device does not have an active subscription with
        protekt. The details of the device will not be accessible until a
        subscription has been purchased.
      </div>
    </b-modal>

    <div class="map-button cluster-button" @click="toggleClustering" id="toggle-cluster-control"
      :class="{disabled: !useClustering}">
      <i class="fa fa-clone" v-b-tooltip title="Toggle Icon Clustering"></i>
    </div>
    <div class="map-button direction-icon-button" @click="toggleDirectionIcons" id="toggle-direction-control"
         :class="{disabled: !useDirectionIcons}">
      <i class="fa fa-location-arrow" v-b-tooltip title="Toggle Direction Icons"></i>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import LoadingBox from '../helpers/LoadingBox'
import moment from 'moment'
import * as DTCHelper from '@/components/helpers/DTCHelper'
import DeviceAvatar from '@/components/device/DeviceAvatar.vue'

export default {
  name: 'device-list',
  components: {DeviceAvatar, LoadingBox },
  data: function () {
    return {
      loading: true,
      // Placeholder for device info
      sortBy: 'plant',
      deviceList: [
        {
          device_code: '',
          device_name: 'Loading...',
          icon: 'Car',
          favorite: false,
          features: []
        }
      ],
      plantList: [],
      isClosing: false,
      dtcHelper: DTCHelper,
      requestedDeviceProperties: [
        'device_imei',
        'device_code',
        'device_name',
        'device_id',
        'location',
        'has_subscription',
        'icon',
        'plant_num',
        'vehicle_dtcs',
        'device_type',
        'device_battery_level'
      ],
      refreshListTimeMilliseconds: 30000,
      refreshTimer: null,
      useClustering: true,
      useDirectionIcons: false,
      userSettings: null,
      listMode: 'all'
    }
  },
  async created () {
    await this.loadUserSettings()
    this.$maps.clearOverlays()
    await this.refreshList()
    this.refreshTimer = setInterval(async () => {
      await this.refreshList(false)
    }, this.$config.general.deviceUpdateDelay * 1000)
  },
  beforeDestroy () {
    // Mark this component as closing so updates will be cancelled.
    this.isClosing = true
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  },
  computed: {
    hasInvalidSubscriptions() {
      if (this.deviceList) {
        return this.deviceList.some(d => d.has_subscription === false)
      } else {
        return false
      }
    }
  },
  methods: {
    async loadUserSettings () {
      // TODO - Make a neater way to load/save user settings
      this.userSettings = await this.$auth.getUserSettings()
      if (Object.hasOwn(this.userSettings, 'deviceListUseClustering')) {
        this.useClustering = this.userSettings.deviceListUseClustering
      }
      if (Object.hasOwn(this.userSettings, 'deviceListUseDirectionIcons')) {
        this.useDirectionIcons = this.userSettings.deviceListUseDirectionIcons
      }
      console.log('user Settings: ', this.userSettings)
    },
    getFavoriteList: async function () {
      let favResponse = await this.$auth.getUserSettings()
      if (favResponse.hasOwnProperty('deviceFavorites')) {
        return favResponse.deviceFavorites
      } else {
        return []
      }
    },
    setFavoriteList: async function () {
      let favorites = this.deviceList.filter(x => x.favorite) // .map(x => x.imei)
      favorites = favorites.map(x => x.device_imei)
      let setResponse = await this.$auth.setUserSettings({
        deviceFavorites: favorites
      })
      if (setResponse.error) {
        console.log('Error saving favourites!')
      }
    },
    toggleDeviceFavorite: function (device) {
      device.favorite = !device.favorite
      this.deviceList = this.sortDeviceList(this.deviceList)
      this.setFavoriteList()
    },
    refreshList: async function (autoZoom = true) {
      if (this.isClosing) {
        // If we aren't in a state where we should be updating, don't update!
        return
      }
      try {
        let favorites = await this.getFavoriteList()
        let res = await DataProvider.getDeviceList(
          this.requestedDeviceProperties
        )
        // Check if our query was successful
        console.log(res)
        if (!res.success) {
          ErrorHelper.displayDataErrorToast(res)
          return // abort!
        }
        let data = []
        // Convert props into an Array for convenience
        for (let item in res.data) {
          data.push(res.data[item])
          res.data[item].favorite = favorites.includes(
            res.data[item].device_imei
          )
          // Assign a Fake name to devices with no name to prevent errors
          if (!res.data[item].device_name) {
            res.data[item].device_name = 'Unnamed'
          }
          res.data[item].features = await this.$deviceService.getDeviceFeatures(
            res.data[item].device_type
          )
          // If the device has trips (and this is a vehicle) show an ACC icon
          if (res.data[item].features.includes('device_trips')) {
            // if it has a location with an acc_on property use that
            if (
              res.data[item].location &&
              Object.hasOwn(res.data[item].location.data, 'acc_on')
            ) {
              res.data[item].acc_on = res.data[item].location.data.acc_on
            } else {
              // Otherwise false
              res.data[item].acc_on = false
            }
          } else {
            res.data[item].acc_on = null
          }
          // Device Vattery Level
          if (res.data[item].features.includes('device_battery_level')) {
            if (
              Object.hasOwn(res.data[item], 'device_battery_level') &&
              res.data[item].device_battery_level < 15
            ) {
              res.data[item].battery_low = true
            } else {
              res.data[item].battery_low = false
            }
          } else {
            res.data[item].battery_low = null
          }
        }
        data.forEach(x => {
          if (!x.icon) {
            x.icon = 'Car'
          }
          if (!x.device_name) {
            x.device_name = 'Unnamed'
          }
        })
        this.deviceList = this.sortDeviceList(data)

        // Build a list of plants and associated devices
        this.plantList = []
        for (let device of this.deviceList) {
          let plantName = device.plant_num

          let plant = this.plantList.find(x => x.plant === device.plant_num)

          if (plant === undefined) {
            let plantText = plantName || 'None'
            this.plantList.push({ plant: plantName, devices: [device], text: plantText })
          } else {
            plant.devices.push(device)
          }
        }
        console.log(this.deviceList)
        this.updateDeviceLocations(autoZoom)
        this.loading = false
      } catch (err) {
        console.error(err)
        this.$bvToast.toast(
          'An Error Occurred Getting a Device List from the Server.',
          {
            title: 'Server Communication Error',
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          }
        )
      }
    },
    sortDeviceList: function (deviceList) {
      let favDevices = deviceList.filter(device => device.favorite)
      let normalDevices = deviceList.filter(device => !device.favorite)
      favDevices.sort(this.safeLocaleCompare)
      normalDevices.sort(this.safeLocaleCompare)
      return favDevices.concat(normalDevices)
    },
    safeLocaleCompare: function (a, b) {
      if (a.device_name) {
        if (b.device_name) {
          return a.device_name.localeCompare(b.device_name)
        } else {
          return -1
        }
      } else {
        return 1
      }
    },
    clickDevice: function (device) {
      if (
        device.has_subscription ||
        this.$auth.roleIn(this.$config.roles.admin)
      ) {
        this.$router.push({ path: 'device/' + device.device_imei })
      } else {
        this.$bvModal.show('modal-no-sub')
      }
    },
    updateDeviceLocations: async function (autoZoom = true) {
      let deviceMarkers = []
      let devices = this.deviceList.filter(x => x.has_subscription)
      for (let device of devices) {
        try {
          if (device.location && this.useDirectionIcons && Object.hasOwn(device.location.data, 'direction')) {
            deviceMarkers.push({
              title: device.device_name,
              direction_arrow: device.acc_on ? 'arrowCircleOn' : 'arrowCircle',
              position: {
                lat: device.location.latitude,
                lng: device.location.longitude,
                direction: device.location.data.direction
              },
              link: 'device/' + device.device_imei
            })
          } else if (device.location) {
            // let locationData = await DataProvider.getDeviceLocation(device.device_imei)
            deviceMarkers.push({
              title: device.device_name,
              icon: device.icon,
              position: {
                lat: device.location.latitude,
                lng: device.location.longitude
              },
              link: 'device/' + device.device_imei
            })
            // This can take a while to complete, so it's possible the user will click on a device while we're waiting.
            // If they do cancel the update.
            if (this.isClosing) {
              console.log('Cancelled Fleet Screen Load')
              return
            }
          }
        } catch (e) {
          console.log('Failed to get location for ' + device.device_code)
          console.error(e)
        }
      }
      this.$maps.updateMarkers(deviceMarkers, true, autoZoom, this.useClustering)
    },
    formatDTCTimestamp: function (device) {
      return moment
        .unix(device.vehicle_dtcs.timestamp)
        .local()
        .format('YYYY-MM-DD HH:mm')
    },
    async toggleClustering () {
      this.useClustering = !this.useClustering
      // The API endpoint for changing user settings is non-destructive so we can send just the single prop to change
      let resp = await DataProvider.setUserSettings({ deviceListUseClustering: this.useClustering })
      if (!resp.success) {
        console.error('Failed to save user settings!')
      } else {
        this.$auth.expireUserData()
      }
      this.updateDeviceLocations(false)
    },
    async toggleDirectionIcons () {
      this.useDirectionIcons = !this.useDirectionIcons
      // The API endpoint for changing user settings is non-destructive so we can send just the single prop to change
      let resp = await DataProvider.setUserSettings({ deviceListUseDirectionIcons: this.useDirectionIcons })
      if (!resp.success) {
        console.error('Failed to save user settings!')
      } else {
        this.$auth.expireUserData()
      }
      this.updateDeviceLocations(false)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

.device-list {
  position: relative;
}

.sub-heading {
  color: $theme-color-primary-5;
  font-weight: 600;
  margin-top: 1vw;
}

//.right_menu_item {
//  text-align: left;
//  background: rgba(255, 255, 255, 0.1);
//  margin: 2px;
//  padding: 2px 5px;
//}

.right_menu_button {
  color: $text-color-invert;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  max-width: 180px;
  text-align: left;
}

.list-icon-white {
  font-size: 0.8em;
  color: $text-color-invert;
}

//.list-icon-red {
//  font-size: 0.8em;
//  color: red;
//}
//
//.list-icon-green {
//  font-size: 0.8em;
//  color: $theme-color-primary-3;
//}
//
//.battery-icon {
//  transform: rotate(-90deg);
//}
//
//.dtc_codes {
//  font-size: 0.8em;
//  color: yellow;
//}

.favorite_icon {
  font-size: 1em;
  color: yellow;
  margin: 0px 0px 0px 5px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50;
}

.map-container {
  width: calc(100% - 310px);
  position: absolute;
  top: 0;
  right: 310px;
  height: 100%;
}

.unpaid {
  background: rgba(241, 66, 66, 20%);
}

.unpaid:active,
.unpaid:hover {
  background: rgba(241, 66, 66, 30%) !important;
}

.plant-heading {
  background: $theme-header-gradient;
  text-align: start;
  color: white;
  padding-left: 1em;
  border-radius: 5px 5px 0px 0px;
}

.unpaid-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vw - 310px);
  height: 2em;
}

</style>
