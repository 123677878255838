var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-list" },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "green-divider" }),
          _c(
            "b-form-radio-group",
            {
              attrs: { buttons: "", "button-variant": "success" },
              model: {
                value: _vm.listMode,
                callback: function($$v) {
                  _vm.listMode = $$v
                },
                expression: "listMode"
              }
            },
            [
              _c("b-form-radio", { attrs: { value: "all" } }, [_vm._v("All")]),
              _c("b-form-radio", { attrs: { value: "plant" } }, [
                _vm._v("By Plant")
              ])
            ],
            1
          ),
          _vm.hasInvalidSubscriptions
            ? _c(
                "div",
                { staticClass: "unpaid-popup" },
                [
                  _c(
                    "b-alert",
                    { attrs: { variant: "danger", show: "", dismissible: "" } },
                    [
                      _c(
                        "p",
                        [
                          _vm._v(
                            "One or more of your devices currently has an expired subscription, which has temporarily paused tracking. To resume tracking, please update your subscription details using the "
                          ),
                          _c("strong", [_vm._v("Subscription")]),
                          _vm._v(" tab of the "),
                          _c("strong", [_vm._v("Preferences page")]),
                          _vm._v(" or by "),
                          _c("router-link", { attrs: { to: "/subscribe" } }, [
                            _vm._v("clicking here.")
                          ])
                        ],
                        1
                      ),
                      _vm._v(
                        " If you need any assistance, feel free to email our support team at support@protektgps.com or call 1300 95 25 30."
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("loading-box", {
            attrs: { loading: _vm.loading, fontsize: "2em" }
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.listMode === "all",
                  expression: "listMode==='all'"
                }
              ],
              staticClass: "device-list"
            },
            [
              _c("h1", { staticClass: "sidebar-heading mt-2" }, [
                _vm._v("All Devices")
              ]),
              _vm.deviceList.length === 0
                ? _c("div", { staticClass: "sub-heading" }, [
                    _vm._v(
                      " You don't have any devices yet. Click 'Add Device' to claim one. "
                    )
                  ])
                : _vm._e(),
              _vm._l(_vm.deviceList, function(device) {
                return _c(
                  "div",
                  {
                    key: device.device_id,
                    class: { unpaid: !device.has_subscription }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "position-relative mt-1" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" }
                            ],
                            staticClass: "favorite_icon clickable",
                            attrs: {
                              id: "fav-icon" + device.device_id,
                              title: "Toggle Favourite"
                            },
                            on: {
                              click: function($event) {
                                return _vm.toggleDeviceFavorite(device)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa clickable",
                              class: {
                                "fa-star": device.favorite,
                                "fa-star-o": !device.favorite
                              }
                            })
                          ]
                        ),
                        _c("DeviceAvatar", {
                          attrs: {
                            device: device,
                            "show-indicators": "",
                            enable_link: ""
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.listMode === "plant",
                  expression: "listMode==='plant'"
                }
              ],
              staticClass: "device-list"
            },
            [
              _c("h1", { staticClass: "sidebar-heading mt-2" }, [
                _vm._v("Devices by Plant")
              ]),
              _vm.deviceList.length === 0
                ? _c("div", { staticClass: "sub-heading" }, [
                    _vm._v(
                      " You don't have any devices yet. Click 'Add Device' to claim one. "
                    )
                  ])
                : _vm._e(),
              _vm._l(_vm.plantList, function(plant) {
                return _c(
                  "div",
                  {
                    key: plant.plant,
                    staticClass: "right_menu_item sub-heading"
                  },
                  [
                    _c("div", { staticClass: "plant-heading" }, [
                      _vm._v("Plant: " + _vm._s(plant.text))
                    ]),
                    _vm._l(plant.devices, function(device) {
                      return _c(
                        "div",
                        { key: device.device_id, staticClass: "mt-1" },
                        [
                          _c("DeviceAvatar", {
                            attrs: {
                              device: device,
                              "show-indicators": "",
                              enable_link: ""
                            }
                          })
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-no-sub",
            centered: "",
            size: "lg",
            "hide-footer": "",
            title: "No Valid Subscription"
          }
        },
        [
          _c("div", [
            _vm._v(
              " The owner of this device does not have an active subscription with protekt. The details of the device will not be accessible until a subscription has been purchased. "
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "map-button cluster-button",
          class: { disabled: !_vm.useClustering },
          attrs: { id: "toggle-cluster-control" },
          on: { click: _vm.toggleClustering }
        },
        [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "fa fa-clone",
            attrs: { title: "Toggle Icon Clustering" }
          })
        ]
      ),
      _c(
        "div",
        {
          staticClass: "map-button direction-icon-button",
          class: { disabled: !_vm.useDirectionIcons },
          attrs: { id: "toggle-direction-control" },
          on: { click: _vm.toggleDirectionIcons }
        },
        [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "fa fa-location-arrow",
            attrs: { title: "Toggle Direction Icons" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }